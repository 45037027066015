'use strict'

angular
  .module 'mundoAdmin.incidents'
  # .config ($stateProvider) ->
  #   $stateProvider
  #     .state 'incidents',
  #       url: '/incidents'
  #       parent: 'admin'
  #       templateUrl: 'mundo-admin/incidents/views/incidents.tpl.html'
  #       controller: 'TabsCtrl'
  #       controllerAs: 'incidentManagementCtrl'
  #       deepStateRedirect: { default: { state: 'incidents.incidents' } },

  #     .state 'incidents.incidents',
  #       url: '/incidents'
  #       data:
  #         'selectedTab': 0
  #       views:
  #         'incidents@incidents':
  #           templateUrl: 'mundo-components/mundo-data-table/views/mundo-data-table.tpl.html'
  #           controller: 'DataTableCtrl'
  #           controllerAs: 'listCtrl'
  #           resolve:
  #             entityManager: (IncidentsManager) ->
  #               IncidentsManager
  #             pageTitle: ->
  #               'app.admin.pageTitles.incidents.incidents'
  #             pageDescription: ->
  #               'app.admin.descriptions.incidents.incidents'
  #             deleteBoxTitle: ->
  #               'app.admin.actions.incidents.deleteIncident'
  #             deleteBoxBody: ->
  #               'app.admin.actions.incidents.deleteIncident'
  #             AddBoxTitle: ->
  #               'app.admin.actions.incidents.addIncident'
  #             editBoxTitle: ->
  #               'app.admin.actions.incidents.editIncident'
  #             loadFilterParams: ->
  #               {
  #                 'filter[closedAt]': 'OR,closedAt,NULL,closedAt,GTE ' + moment.utc().format()
  #               }
  #     .state 'incidents.types',
  #       url: '/types'
  #       data:
  #         'selectedTab': 1
  #       views:
  #         'types@incidents':
  #           templateUrl: 'mundo-components/mundo-data-table/views/mundo-data-table.tpl.html'
  #           controller: 'DataTableCtrl'
  #           controllerAs: 'listCtrl'
  #           resolve:
  #             entityManager: (IncidentTypesManager) ->
  #               IncidentTypesManager
  #             pageTitle: ->
  #               'app.admin.pageTitles.incidents.types'
  #             pageDescription: ->
  #               'app.admin.descriptions.incidents.types'
  #             deleteBoxTitle: ->
  #               'app.admin.actions.incidents.deleteType'
  #             deleteBoxBody: ->
  #               'app.admin.actions.incidents.deleteType'
  #             AddBoxTitle: ->
  #               'app.admin.actions.incidents.addType'
  #             editBoxTitle: ->
  #               'app.admin.actions.incidents.editType'
  #             loadFilterParams: ->
  #               {}
  #     .state 'incidents.priorities',
  #       url: '/priorities'
  #       data:
  #         'selectedTab': 2
  #       views:
  #         'priorities@incidents':
  #           templateUrl: 'mundo-components/mundo-data-table/views/mundo-data-table.tpl.html'
  #           controller: 'DataTableCtrl'
  #           controllerAs: 'listCtrl'
  #           resolve:
  #             entityManager: (IncidentPrioritiesManager) ->
  #               IncidentPrioritiesManager
  #             pageTitle: ->
  #               'app.admin.pageTitles.incidents.priorities'
  #             pageDescription: ->
  #               'app.admin.descriptions.incidents.priorities'
  #             deleteBoxTitle: ->
  #               'app.admin.actions.incidents.deletePriority'
  #             deleteBoxBody: ->
  #               'app.admin.actions.incidents.deletePriority'
  #             AddBoxTitle: ->
  #               'app.admin.actions.incidents.addPriority'
  #             editBoxTitle: ->
  #               'app.admin.actions.incidents.editPriority'
  #             loadFilterParams: ->
  #               {}
